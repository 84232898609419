<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        Activity
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="tourGuide"
          v-model="statusTourGuide"
          :unchecked-value="false"
          value="guide"
          name="check-button"
          switch
          inline
        >
          <span>Tour Guide</span>
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="translator"
          v-model="statusTranslator"
          value="translator"
          :unchecked-value="false"
          name="check-button"
          switch
          inline
        >
          <span>Translator</span>
        </b-form-checkbox>
      </b-col></b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { showToast } from '@/mixins/notification/toasts'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statusTourGuide: {
      get() {
        return this.accountData.services.includes('guide') ? 'guide' : false
      },
      set(newValue) {
        this.setService(newValue, 'guide')
      },
    },
    statusTranslator: {
      get() {
        return this.accountData.services.includes('translator') ? 'translator' : false
      },
      set(newValue) {
        this.setService(newValue, 'translator')
      },
    },
  },
  methods: {
    setService(value, slug) {
      if (value === false) {
        const index = this.accountData.services.indexOf(slug)
        if (index > -1) {
          this.accountData.services.splice(index, 1)
        }
      } else if (!this.accountData.services.includes(slug)) {
        this.accountData.services.push(value)
      }
    },
  },

}
</script>
