<template>
  <component :is="accountData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="accountData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id
      </div>
    </b-alert>
    <validation-observer ref="accountSettings">
      <b-tabs
        v-if="accountData"
        pills
      >
        <b-tab
          v-for="tab in tabs"
          :key="tab.dataField"
        >
          <template #title>
            <feather-icon
              :icon="tab.icon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ tab.label }}</span>
          </template>
          <component
            :is="tab.component"
            :account-data="accountData"
            class="mt-2 pt-75"
            @sections="setDefaultSections"
          /></b-tab>
      </b-tabs>
    </validation-observer>
    <b-col cols="12">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-2 mr-1"
        @click="submit"
      >
        Save changes
      </b-button>
    </b-col>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { showToast } from '@/mixins/notification/toasts'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import router from '@/router'
import AccountSettingTabGeneral from './AccountSettingTabGeneral.vue'
import AccountSettingTabPassword from './AccountSettingTabPassword.vue'
import AccountSettingTabInformation from './AccountSettingTabInformation.vue'
import AccountSettingTabSocial from './AccountSettingTabSocial.vue'
import AccountSettingTabServices from './AccountSettingTabServices.vue'
import AccountSettingTabNotification from './AccountSettingTabNotification.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BButton,
    ValidationObserver,

    AccountSettingTabGeneral,
    AccountSettingTabPassword,
    AccountSettingTabInformation,
    AccountSettingTabSocial,
    AccountSettingTabServices,
    AccountSettingTabNotification,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],

  // mounted() {
  //   this.$watch(() => this.accountData, () => {
  //     store.commit('account/setAccountSettings', this.accountData)
  //   }, { deep: true, immediate: true })
  // },
  data() {
    return {
      saved: false,
      accountData: {
        name: '',
        email: '',
        login: '',
        phone: '',
        public_figure: null,
        bio: '',
        residence_country: '',
        residence_city: '',
        twitter: null,
        facebook: null,
        linkedin: null,
        instagram: null,
        services: [],
        languages: [],
      },
      savedAccountData: {},
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.savedAccountData !== JSON.stringify(this.accountData)) {
      this.showAlert(next)
    } else {
      next()
    }
  },
  beforeCreate() {
    store.dispatch('account/fetchAccountSettings')
      .then(response => {
        this.accountData = response.data.data
        this.savedAccountData = JSON.stringify(this.accountData)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.accountData = undefined
        }
      })
  },
  methods: {
    setDefaultSections(obj) {
      const tab = Object.keys(obj)[0]
      Object.values(obj)[0].forEach(section => {
        if (!this.accountData[tab][section]) {
          this.accountData[tab][section] = {}
        }
      })
    },
    showAlert(next) {
      this.$swal({
        title: 'Are you sure?',
        text: "You didn't save the Account Settings!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, I\'m sure',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          next()
        }
      })
    },
    submit() {
      this.$refs.accountSettings.validate().then(success => {
        if (success) {
          this.save()
        }
      })
    },
    save() {
      store.dispatch('account/updateAccountSettings', this.accountData).then(response => {
        this.savedAccountData = JSON.stringify(this.accountData)
        this.showToast(response, 'Account Settings')
      })
    },
  },
  setup() {
    const tabs = [
      {
        icon: 'UserIcon',
        dataField: 'general',
        component: 'AccountSettingTabGeneral',
        label: 'Account',
      },
      // {
      //   icon: 'KeyIcon',
      //   dataField: 'password',
      //   component: 'AccountSettingTabPassword',
      //   label: 'Change Password',
      // },
      {
        icon: 'InfoIcon',
        dataField: 'info',
        component: 'AccountSettingTabInformation',
        label: 'Information',
      },
      {
        icon: 'Share2Icon',
        dataField: 'social',
        component: 'AccountSettingTabSocial',
        label: 'Social',
      },
      {
        icon: 'BriefcaseIcon',
        dataField: 'services',
        component: 'AccountSettingTabServices',
        label: 'Services',
      },
    ]

    return {
      tabs,
    }
  },
}
</script>
