<template>
  <b-form>
    <b-row>
      <b-col
        v-for="socialField in socialLinksInputs"
        :key="socialField.dataField"
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          :label="socialField.label"
          :label-for="socialField.dataField"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                size="16"
                :icon="socialField.icon"
              />
            </b-input-group-prepend>
            <b-form-input
              :id="socialField.dataField"
              v-model="accountData[socialField.dataField]"
              type="url"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import { showToast } from '@/mixins/notification/toasts'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
  },
  mixins: [showToast],
  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const socialLinksInputs = [
      {
        icon: 'TwitterIcon',
        dataField: 'twitter',
        label: 'Twitter',
      },
      {
        icon: 'FacebookIcon',
        dataField: 'facebook',
        label: 'Facebook',
      },
      {
        icon: 'InstagramIcon',
        dataField: 'instagram',
        label: 'Instagram',
      },
    ]
    return {
      socialLinksInputs,
    }
  },

}
</script>

<style>

</style>
