<template>
  <div>

    <!-- Media -->
    <!--    <b-media class="mb-2">-->
    <!--      <template #aside>-->
    <!--        <b-avatar-->
    <!--          ref="previewEl"-->
    <!--          :src="accountData.avatar"-->
    <!--          :text="avatarText(accountData.name)"-->
    <!--          :variant="`light-${resolveUserRoleVariant(accountData.role)}`"-->
    <!--          size="90px"-->
    <!--          rounded-->
    <!--        />-->
    <!--      </template>-->
    <!--      <h4 class="mb-1">-->
    <!--        {{ accountData.name }}-->
    <!--      </h4>-->
    <!--      <div class="d-flex flex-wrap">-->
    <!--        <b-button-->
    <!--          variant="primary"-->
    <!--          @click="$refs.refInputEl.click()"-->
    <!--        >-->
    <!--          <input-->
    <!--            ref="refInputEl"-->
    <!--            type="file"-->
    <!--            class="d-none"-->
    <!--            @input="inputImageRenderer"-->
    <!--          >-->
    <!--          <span class="d-none d-sm-inline">Update</span>-->
    <!--          <feather-icon-->
    <!--            icon="EditIcon"-->
    <!--            class="d-inline d-sm-none"-->
    <!--          />-->
    <!--        </b-button>-->
    <!--        <b-button-->
    <!--          variant="outline-secondary"-->
    <!--          class="ml-1"-->
    <!--        >-->
    <!--          <span class="d-none d-sm-inline">Remove</span>-->
    <!--          <feather-icon-->
    <!--            icon="TrashIcon"-->
    <!--            class="d-inline d-sm-none"-->
    <!--          />-->
    <!--        </b-button>-->
    <!--      </div>-->
    <!--    </b-media>-->

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Username"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="accountData.login"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="accountData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="accountData.email"
                type="email"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <!--        &lt;!&ndash; Field: Status &ndash;&gt;-->
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="4"-->
        <!--        >-->
        <!--          <b-form-group-->
        <!--            label="Status"-->
        <!--            label-for="user-status"-->
        <!--          >-->
        <!--            <v-select-->
        <!--              v-model="generalData.status"-->
        <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--              :options="statusOptions"-->
        <!--              :reduce="val => val.value"-->
        <!--              :clearable="false"-->
        <!--              input-id="user-status"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <!-- Field: Role -->
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="4"-->
        <!--        >-->
        <!--          <b-form-group-->
        <!--            label="User Role"-->
        <!--            label-for="user-role"-->
        <!--          >-->
        <!--            <v-select-->
        <!--              v-model="generalData.role"-->
        <!--              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--              :options="roleOptions"-->
        <!--              :reduce="val => val.value"-->
        <!--              :clearable="false"-->
        <!--              input-id="user-role"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <!-- Field: Email -->
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="4"-->
        <!--        >-->
        <!--          <b-form-group-->
        <!--            label="Company"-->
        <!--            label-for="company"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--              id="company"-->
        <!--              v-model="generalData.company"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <!-- Field: Mobile -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Phone"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="accountData.phone"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import {
  required, email,
} from '@validations'
import useUsersList from '../../apps/user/users-list/useUsersList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    required,
    email,
  },
  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.data.avatar = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
