<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Personal Information
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-row>
      <!-- Field: Bio -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Bio"
          label-for="bio-area"
        >
          <b-form-textarea
            id="bio-area"
            v-model="accountData.bio"
            rows="4"
            placeholder="Your bio data here..."
          />
        </b-form-group>
      </b-col>
      <!-- Field: Language -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Publicity"
          label-for="publicFigure"
        ><b-form-checkbox
          id="publicFigure"
          v-model="accountData.public_figure"
          :checked="true"
          :value="true"
          :unchecked-value="false"
          name="check-button"
          switch
          inline
        >
          <span>I am a Public Figure</span>
        </b-form-checkbox>
        </b-form-group>
        <b-form-group
          label="Language"
          label-for="language"
        >
          <v-select
            v-model="accountData.languages"
            :options="optionLanguages"
            :reduce="text => text.value"
            label="text"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
          >
            <template #option="{ text }">
              <span> {{ text }}</span>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Header: Personal Info -->
    <div class="d-flex mt-2">
      <feather-icon
        icon="MapPinIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Location
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-row class="mt-1">
      <!-- Field: City -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="City"
          label-for="city"
        >
          <b-form-input
            id="city"
            v-model="accountData.residence_city"
          />
        </b-form-group>
      </b-col>
      <!-- Field: Country -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="Country"
          label-for="country"
        >
          <v-select
            id="countryList"
            v-model="accountData.residence_country"
            :options="optionCountries"
            :reduce="text => text.value"
            label="text"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormTextarea,
  BRow,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'
import { showToast } from '@/mixins/notification/toasts'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, flatPickr, BFormInput, vSelect, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormTextarea, BFormCheckbox,
  },
  mixins: [showToast],
  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const optionCountries = store.getters['appOptions/countries']
    const optionLanguages = store.getters['appOptions/languages']

    return {
      optionCountries,
      optionLanguages,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
